import styled from "styled-components";
import { Header, Sidebar, SubscribeCard } from "../components/index";
import { useContext, useEffect, useState } from "react";
import { TitleText, SubText, Content } from "../styles";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import { auth, db } from "../firebase/firebase";
import { useMediaQuery } from "@mui/material";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import SubscriptionHistory from "../usertables/SubscribeHistory";
import { userContext } from "../context/userContext";
import toast from "react-hot-toast";
import SignalCard from "../components/SignalCard";
import AddSignalModal from "../modals/AddSignalModal";
import SignalHistory from "../usertables/SignalHistory";
import SignalBalanceCard from "../components/SignalBalanceCard";

const Signals = () => {
  const { userData } = useContext(userContext);
  const [menuSmall, setMenuSmall] = useState(false);
  const mobile = useMediaQuery("(max-width: 800px)");
  const [isOpen, setIsOpen] = useState(mobile ? false : true);
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [signals, setSignals] = useState([]);
  const [addSignal, setAddSignal] = useState(false);

  useEffect(() => {
    if (user) {
      onSnapshot(doc(db, "users", user.uid), (doc) => {
        const data = doc.data();

        if (data && !data.verified) {
          navigate("/newverify");
          toast.error("Verify your account to continue.");
        }
      });
    }
  }, [user]);

  useEffect(() => {
    async function fetchPlans() {
      const docRef = doc(db, "admin", "signals");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setSignals(Object.values(docSnap.data()));
        setLoader(false);
      } else {
        console.log("No such document!");
      }
    }

    if (!loading && user) {
      fetchPlans();
    }

    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading, navigate]);

  // useEffect(() => {
  //   if (user) {
  //     onSnapshot(doc(db, "users", user.uid), (doc) => {
  //       const data = doc.data();

  //       if (data && !data.verified) {
  //         navigate("/newverify");
  //         toast.error("Verify your account to continue.");
  //       }
  //     });
  //   }
  // }, [user]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Wrapper>
          {addSignal && <AddSignalModal open={{ addSignal, setAddSignal }} />}

          <Sidebar
            menu={[menuSmall, setMenuSmall]}
            active="Signals"
            open={[isOpen, setIsOpen]}
          />
          <Main>
            <Header
              menu={[menuSmall, setMenuSmall]}
              open={[isOpen, setIsOpen]}
            />
            <Content className="content">
              <TitleText>Signals</TitleText>

              {/* <SignalBalanceCard /> */}

              <ContentWrapper>
                <SignalBalanceCard />

                <SubText>Active signals</SubText>

                {userData.admin && (
                  <button
                    className="actionButton"
                    style={{ maxWidth: "max-content", marginBottom: "-34px" }}
                    onClick={() => setAddSignal(true)}
                  >
                    <p className="actionText">Add new signal</p>
                  </button>
                )}

                <ContentContainer>
                  {signals.map((signal) => (
                    <SignalCard details={signal} key={signal.name} />
                  ))}
                </ContentContainer>
              </ContentWrapper>
              <SignalHistory user={user} />
              <br />
              <br />
            </Content>
          </Main>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  background: #0e121b;
  color: white;
  width: 100vw;
  height: 100vh;
  /* overflow: auto; */
`;

const Main = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 100%;
  overflow-y: auto;

  .content {
    max-width: 100%;
    box-sizing: border-box;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  box-sizing: border-box;
  gap: 80px;
  height: fit-content;
  margin-top: 50px;
  padding-bottom: 60px;
  /* height: 100%; */

  label {
    color: #a3a3a3;
    font-weight: 600;
  }

  .bottomBox {
    display: grid;
    gap: 0.5rem;
  }

  .contentBox {
    border-radius: 0.5rem;
    color: white;
    cursor: pointer;
    text-align: left;
    border: none;
  }

  .inputBox {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #1f273a;
    background-color: transparent;
    outline: none;
    color: #fff;
    border-radius: 0.5rem;
    cursor: pointer;
    padding: 1rem;
  }
`;

const ContentContainer = styled.div`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem;

  @media screen and (max-width: 800px) {
    display: grid;
    grid-template-columns: auto;
    justify-self: center;
  }
`;

export default Signals;
