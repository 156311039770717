import styled from "styled-components";
import { Header, Sidebar } from "../components/index";
import NFTCards from "../components/NFTCards";
import { useContext, useEffect, useState } from "react";
import {
  TitleText,
  Content,
  PageSwitcher,
  InfoBox,
  OutlinedBox,
  BlueButton,
} from "../styles";
import ConnectWalletModal from "./ConnectWalletModal";
import { useAddress, useDisconnect, useMarketplace } from "@thirdweb-dev/react";
import CircularLoader from "./CircularLoader";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Loader from "./Loader";
import { useMediaQuery } from "@mui/material";
import YourNFTs from "../components/YourNFTs";
import StakeHistory from "../usertables/StakeHistory";
import { useMoralis } from "react-moralis";
import toast from "react-hot-toast";
import { userContext } from "../context/userContext";
import { doc, onSnapshot } from "firebase/firestore";

const NFTs = () => {
  const [menuSmall, setMenuSmall] = useState(false);
  const mobile = useMediaQuery("(max-width: 900px)");
  const [isOpen, setIsOpen] = useState(mobile ? false : true);
  const pages = ["Marketplace", "Your NFTs", "Stakings"];
  const [activePage, setActivePage] = useState("Marketplace");
  const [connectWallet, setConnectWallet] = useState(false);
  const [activeListings, setActiveListings] = useState(false);
  const address = useAddress();
  const disconnect = useDisconnect();
  const [isLoading, setIsLoading] = useState(false);
  // const [singleNft, setSingleNft] = useState(false)
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [loader, setLoader] = useState(user !== null ? false : true);
  const { userData } = useContext(userContext);

  const { isAuthenticated, logout } = useMoralis();

  useEffect(() => {
    if (user) {
      onSnapshot(doc(db, "users", user.uid), (doc) => {
        const data = doc.data();

        if (data && !data.verified) {
          navigate("/newverify");
          toast.error("Verify your account to continue.");
        }
      });
    }
  }, [user]);

  useEffect(() => {
    if (!loading && user && userData.blocked) {
      navigate("/login");
      toast.error("Your account has been banned.");
    }

    if (!loading && user && !userData.blocked) {
      setLoader(false);
    }

    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading, navigate, userData]);

  useEffect(() => {
    if (user) {
      onSnapshot(doc(db, "users", user.uid), (doc) => {
        const data = doc.data();

        if (data && !data.verified) {
          navigate("/newverify");
          toast.error("Verify your account to continue.");
        }
      });
    }
  }, [user]);

  const handleDisconnect = () => {
    setConnectWallet(false);
    disconnect();
    if (isAuthenticated) {
      logout();
    }
  };

  const marketplace = useMarketplace(
    "0x0e3A9830C57768b0e4F1428f75c651F00D7f2eaC"
  );

  useEffect(() => {
    async function fetchActiveListings() {
      try {
        const listings = await marketplace.getActiveListings();
        if (listings) {
          // console.log(listings);
          setActiveListings(listings);
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (marketplace) {
      fetchActiveListings();
    }
  }, [marketplace, activeListings]);

  useEffect(() => {
    if (activeListings) {
      setIsLoading(false);
    }
  }, [activeListings]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Wrapper>
          <Sidebar
            menu={[menuSmall, setMenuSmall]}
            active="NFTs"
            open={[isOpen, setIsOpen]}
          />
          <Main>
            <Header
              menu={[menuSmall, setMenuSmall]}
              open={[isOpen, setIsOpen]}
            />
            {address ? (
              isLoading ? (
                <CircularLoader />
              ) : (
                <Content>
                  <TitleText>NFTs</TitleText>
                  <br />
                  <PageSwitcher className="switcher scrollbar-hidden">
                    {pages.map((page) => (
                      <button
                        key={page}
                        value={page}
                        className={page === activePage ? "active" : ""}
                        onClick={(e) => setActivePage(e.target.value)}
                      >
                        {page}
                      </button>
                    ))}
                  </PageSwitcher>

                  <button
                    onClick={handleDisconnect}
                    className="disconnectSwitch"
                  >
                    <div className="disconnectLeft">
                      <p>Connected as</p>
                      <p>
                        {address.substring(0, 5)}....
                        {address.substring(address.length - 4)}
                      </p>
                    </div>

                    <button className="actionButton">
                      <p className="actionText">Disconnect</p>
                    </button>
                  </button>
                  {activePage === "Marketplace" && (
                    <ContentWrapper>
                      <NFTCards
                        listings={activeListings}
                        market={marketplace}
                      />
                    </ContentWrapper>
                  )}

                  {activePage === "Your NFTs" && (
                    <ContentWrapper>
                      <YourNFTs
                        listings={activeListings}
                        market={marketplace}
                      />
                    </ContentWrapper>
                  )}

                  {activePage === "Stakings" && (
                    // <ContentWrapper>
                    <StakeHistory />
                    // </ContentWrapper>
                  )}
                </Content>
              )
            ) : (
              <Content className="contentWallet">
                <img
                  src="https://uploads-ssl.webflow.com/60098c5b239e4890fa5ee1aa/61d4b23e0885ed1a0c13bb42_nft-collectibles-hero.png"
                  alt="market"
                  width={240}
                />

                <div className="walletConnect">
                  <div className="texts">
                    {/* <h1>Connect your wallet</h1> */}
                  </div>

                  <OutlinedBox className="max-w-[max-content]">
                    <p className="infot font-base">
                      You need to connect your wallet to continue.
                    </p>
                  </OutlinedBox>

                  <BlueButton
                    className="mt-2"
                    onClick={() => setConnectWallet(true)}
                  >
                    Connect Wallet
                  </BlueButton>
                  {/* </button> */}
                  {connectWallet && (
                    <ConnectWalletModal
                      open={{ connectWallet, setConnectWallet }}
                    />
                  )}
                </div>
              </Content>
            )}
          </Main>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  background: #0e121b;
  color: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .contentWallet {
    margin-top: 20px;
    height: 100%;
    overflow-x: hidden;
    padding: 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    img {
      margin-top: 20px;
    }
  }

  .walletConnect {
    place-self: center;
    background: #161c2a;
    border: 1px solid #1f273a;
    box-sizing: border-box;
    border-radius: 8px;
    width: 360px;
    padding: 20px;
    /* text-align: center; */
    /* margin: 40px; */
    margin-top: 50px;
    display: grid;
    gap: 1rem;
    justify-items: center;

    @media screen and (max-width: 800px) {
      width: 100%;
    }

    button {
      max-width: fit-content;
    }

    .texts {
      text-align: center;

      h1 {
        font-size: 20px;
        font-weight: 600;
        line-height: 29px;
        color: #ffffff;
        /* font-family: "Inter"; */
      }
    }

    .text {
      font-family: "Inter";
      font-style: normal;
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: #c9c9c9;
    }
  }

  .switcher {
    width: 100%;
    display: flex;
    overflow-x: auto;

    button {
      min-width: max-content;
      display: flex;
    }
  }
`;

const Main = styled.div`
  width: 100%;
  max-height: 100vh;
  box-sizing: border-box;

  overflow-y: auto;

  .disconnectSwitch {
    background: #0e121b;
    border: 1px solid #3a4867;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 1rem;
    width: 360px;
    margin-top: 30px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;

    @media screen and (max-width: 900px) {
      width: 100%;
    }

    .disconnectLeft {
      display: grid;
      /* gap: 0.8rem; */
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  /* place-content: center; */
  /* grid-template-columns: 360px auto; */
  /* grid-template-rows: auto auto; */
  /* box-sizing: border-box; */
  gap: 1rem;
  height: fit-content;
  margin-top: 50px;
  width: 100%;
  height: max-content;
  padding-bottom: 100px !important;
  /* margin-bottom: 60px; */
  flex-wrap: wrap;
  /* padding-bottom: 60px; */

  @media screen and (max-width: 900px) {
    padding-bottom: 100px !important;
    display: grid;
  }
`;

export default NFTs;
